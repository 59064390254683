@import './variables';

.richtext {
    font-family: $sans-serif-normal;
    margin-top: 48px;
    margin-bottom: 48px;

    & + & {
        margin-top: 20px;
    }
    
    &:last-child {
        margin-bottom: 48px;
    }

    + * {
        clear: both; // In case they float images in rich text
    }

    // Styles for finserv imports:
    h2 {
        margin-bottom: 12px;

        &::after {
            content: none;
        }

        &:not(:first-child) {
            margin-top: 12px;
        }

        + p {
            margin-top: 0;
        }
    }

    h3,
    h4 {
        line-height: 24px;
        margin-top: 24px;
        margin-bottom: 8px;
    }

    ol, ul {
        margin-top: 16px;
        margin-bottom: 24px;

        li {
            font-size: 16px;

            p {
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    ol {
        li {
            display: list-item; // Fix for some imported content
        }
    }

    ul {
        margin-left: 22px;
        
        li {
            display: list-item;
            list-style-type: disc;

            &::before {
                content: none;
            }
        }
    }

    dl {

        dt{
            font-size: 18px;
            font-family: $sans-serif-medium;
font-weight: $sans-medium;
            line-height: 32px;
            letter-spacing: normal;
            margin-top: 32px;
            margin-bottom: 10px;
        }
        dd{
            margin-bottom: 10px;
            line-height: 28px;
        }
    }
    
    p,
    address {
        margin-top: 24px;
        margin-bottom: 24px;
    }
}

.sub-page-h2 {
    font-size: 29px;
    margin-right: 32px;
    color: #800000;
    font-family: "adobe-garamond-pro",serif;
    line-height: 48px;
    letter-spacing: normal;
    text-align: left;
    text-transform: none;
    border-bottom: solid;
}

.sub-page-h3 {
font-family: $sans-serif-bold;
font-weight: $sans-bold;
}