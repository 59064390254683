// COVEO News Search Page
$columnBuffer: 4rem;
.NewsSearchInterface {
  .coveo-main-section {
    // Prevent from container being empty and causing content on page to jump
    min-height: 100vh;
    // .coveo-search-section {
    // }
    // Align search result width to edge of search UI
    .coveo-results-column {
      // Place space when the date is right below the title.
      .coveo-date-field {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
      .CoveoResultList {
        max-width: 881px;
      }
      // Align coveo search result template bookmarks at end of results
      .bookmarkContainer {
        margin-left: 1rem;
        span {
          top: -2px;
          right: 0;
          position: relative;
        }
      }
      // Ensure search result takes up max amount of space available
      .textContainer {
        width: 100%;
      }
      .CoveoNewsSource {
        font-size: 16px;
        line-height: 28px;
      }
    }
    .coveo-dropdown-header-wrapper {
      // This must never be shown in the news search page since it has a custom UI for the subscriptions as facetss
      display: none !important;
    }
    .news-search-custom-subs-as-facets-ui {
      // Remove default animation duratiohn so checkmarks appear right away
      svg {
        animation-duration: 0s;
      }
    }
    // Hide the facet column - since we're using a custom UI implementation for subscriptions as facets
    .coveo-facet-column {
      display: none;
    }
    .coveo-facet-dropdown-header {
      display: none;
    }
  }
}
@media (min-width: 1321px) {
  .NewsSearchInterface {
    .coveo-main-section {
      //Instead of display flex use grid to create a 2 dimensional layout
      display: grid;
      /* First column fits to content, second takes remaining space */
      grid-template-columns: min-content auto;
      grid-template-rows: min-content min-content 1fr;
      grid-row-gap: 1rem;
      grid-template-areas:
        "news  search"
        "news results"
        "callout results";
      max-width: 1200px;
      margin: 0 auto;
      .coveo-search-section {
        grid-area: search;
        // Override odd large space margin: 0 auto 43px on search apge, and add space between left column
        margin: 0 auto 0 $columnBuffer;
        // Align page border at top of page and edge of search UI
        max-width: 908px;
        // Reduce large spacing above and below search UI
        padding: 0 0 48px 0;
      }
      .coveo-results-column {
        grid-area: results;
        width: 100%;
        // No content when results are loading
        min-width: 100%;
        margin-left: $columnBuffer;
        display: flex;
      }
      .news-search-custom-subs-as-facets-ui {
        grid-area: news;
      }
      .global-callout-box {
        grid-area: callout;
        margin-top: 4rem;
      }
    }
  }
}

@media (max-width: 1320px) {
  .NewsSearchInterface {
    .coveo-main-section {
      //Hide the call out box in mobile view since it wasn't implemented for mobile
      .global-callout-box {
        display: none;
      }
    }
  }
}
