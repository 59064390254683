/* Box sizing rules */
*,
*:not(.fa)::before,
*::after {
    box-sizing: border-box;
    color: $grey;
    //font-family: $sans-serif-normal;
}

::selection {
    background-color: $lightgrey;
}

/* Remove default margin, padding */
button,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
    padding: 0;
    font-family: $sans-serif-normal;
}

/* Set core body defaults */
body {
    line-height: 1;
    overflow-x: hidden;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Causes underline on links to not draw over low characters like y or g */
a {
    text-decoration-skip-ink: auto;
}

/* Default Images */
img {
    max-width: 100%;
}

/* Global button styles */
button {
    background: transparent;
    cursor: pointer;
    transition: 0.5s ease-in-out;
}

/* Break-all */
strong,b {
    font-family: $sans-serif-bold;
    word-break: break-word;
}

/* Remove Default Browser Input Buttons */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }