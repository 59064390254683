//COVEO Search Page
div.CoveoForSitecoreContext {
    padding-top: 48px;
}
.CoveoSearchInterface .coveo-main-section {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-width: 100%;
    font-family: $sans-normal;

    // Search Bar:
    .coveo-search-section {
        background-color: transparent;
        margin: 0 auto 43px;
        max-width: 100%;
        padding: 0 0 48px;
        width: 100%;

        .coveo-for-sitecore-search-box-container {
            margin: 0 auto;
            max-width: 1002px;
            padding: 0 24px;
        }

        .CoveoSearchbox {
            //border-bottom: 2px solid $lightgrey;

            .magic-box {
                &,
                .magic-box-input {
                    border: 0;
                    border-radius: 0;
                }

                .magic-box-input,
                .magic-box-clear {
                    background-color: transparent;
                }

                .magic-box-input > input {
                    color: $darkgrey;
                    font-family: $sans-serif-normal;
                    font-size: 24px;
                    padding: 3px 24px 3px 10px;
                    border: 3px solid $lightgrey;
                }
            }
        }

        .CoveoSearchButton {
            border: 3px solid $lightgrey;
            border-radius: 0;
            z-index: 0;
            height: 48px;
            width: 58px;
            margin-left: 4px;

            svg * {
                fill: $grey;
            }

            &:hover {
                svg * {
                    fill: $red;
                }
            }
        }
    }

    // Tabs:
    .coveo-tab-section {
        background: transparent;
        border: none;
        margin-bottom: 67px;
        order: -1;

        &::after {
            border-bottom: 2px solid $lightergrey;
            content: "";
            display: block;
            left: 0;
            position: absolute;
            width: 100%;
        }

        .CoveoTab {
            border: none;
            padding: 0 8px;

            p {
                color: $grey;
                font-size: 18px;
                text-transform: none;
            }

            &.coveo-selected {
                position: relative;

                &::after {
                    background-color: $red;
                    bottom: -2px;
                    content: "";
                    height: 2px;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    z-index: 1;
                }

                p {
                    border: none;
                    color: $red;
                    font-family: $sans-serif-medium;
                    font-weight: $sans-medium;
                }
            }

            &.coveo-selected,
            &.coveo-tab-dropdown {
                border: none;
            }
        }
    }

    // Facets Column:
    .coveo-facet-column {
        margin: 120px 63px 0 24px;
        padding: 0;
        width: 229px;

        // Single Facet:
        .CoveoFacet,
        .CoveoFacetRange {
            border-bottom-color: $lightgrey;
               border-width: 0 0 1px;
            margin: 0 0 20px;
            padding: 0 0 20px;

            .coveo-facet-header {
                background-color: transparent;
                padding: 0 0 20px;

                &-title {
                    color: $grey;
                    font-size: 16px;
                    font-family: $sans-serif-bold;
                    font-weight: $sans-bold;
                    line-height: 18px;
                }

                &-settings-section {
                    display: none;
                }
            }

            .coveo-facet-values {
                padding: 0;

                .coveo-facet-value {
                    padding: 0;

                    &:not(:first-of-type) {
                        margin-top: 12px;
                    }

                    &.coveo-selected {
                        font-family: $sans-serif-normal;
                        font-weight: $sans-normal;

                        .coveo-facet-value-checkbox {
                            background-color: $red;

                            &::after {
                                color: $white;
                                content: "\f00c";
                                display: block;
                                font-family: $fontAwesome;
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }
                    }

                    &::before {
                        content: none;
                    }

                    &.coveo-selected,
                    &:hover {
                        .coveo-facet-value-checkbox {
                            svg {
                                display: none;
                            }
                        }
                    }

                    &.coveo-with-hover:hover {
                        background-color: transparent;
                    }

                    &-label {
                        padding: 0;
                        width: 100%;
                    }

                    &-caption,
                    &-count {
                        font-size: 16px;
                        line-height: 24px;
                    }

                    &-caption {
                        white-space: pre-wrap;
                    }

                    &-checkbox {
                        border-color: $mediumergrey;
                        border-radius: 0;
                        flex-shrink: 0;
                        margin-right: 13px;
                    }

                    &-count {
                        order: 1;

                        &::before {
                            content: "(";
                        }

                        &::after {
                            content: ")";
                        }
                    }

                    &-exclude {
                        display: none;
                    }
                }
            }

            .coveo-facet-footer.coveo-facet-empty,
            .coveo-facet-footer:empty {
                display: none;
            }

            &.disable-for-external {
                .coveo-facet-values {
                    pointer-events: none;

                    .coveo-facet-value {
                        .coveo-facet-value-caption {
                            color: $mediumergrey;
                        }

                        .coveo-facet-value-count {
                            display: none;
                        }

                        &.coveo-selected .coveo-facet-value-checkbox {
                            background-color: $grey;
                        }
                    }
                }
            }
        }
    }

    // Results Summary + Sorts:
    .CoveoQuerySummary,
    .coveo-sort-section {
        max-width: 50%;
        width: 376px;
    }

    // Results Summary:
    .CoveoQuerySummary {
        font-size: 16px;
        line-height: 18px;
        order: -1;
        padding-left: 32px;

        .coveo-query-summary-cancel-last,
        .coveo-query-summary-search-tips-info,
        .coveo-query-summary-search-tips-info + ul {
            display: none;
        }
    }

    // Sorts:
    .coveo-sort-section {
        justify-content: flex-end;

        .coveo-sort-container:not(:first-of-type) > span {
            margin-left: 10px;
        }
    }

    // Results List:
    .coveo-results-column {
        display: flex;
        flex-wrap: wrap;
        margin-right: 24px;
        max-width: calc(1200px - 340px);
        order: 1;
        padding: 0;
        width: calc(100% - 340px);
        overflow: inherit !important;

        .CoveoResultList {
            border-top: 1px solid $lightgrey;
            margin: 24px 0 47px;
            max-width: 752px;
            padding-top: 16px;
            width: 100%;

            .coveo-result-frame .coveo-result-row {
                margin: 0;
            }

            // Result Item:
            .coveo-list-layout.CoveoResult {
                border-bottom: 1px solid $lightgrey;
                margin: 0 32px;
                padding: 16px 0;

                .directoryitem-title {
                    font-family: $sans-serif-bold;
                    font-weight: $sans-bold;
                    margin-bottom: 8px;
                }

                .positiondata {
                    + .positiondata {
                        margin-top: 12px;
                    }

                    [class*="positiondata-"] {
                        line-height: 24px;

                        span {
                            font-family: $sans-serif-medium;
                            font-weight: $sans-medium;
                        }

                        a {
                            color: $red;

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }

                .result-list-item {
                    display: flex;

                    &-personCard {
                        align-items: center;
                    }

                    &-people {
                        [data-field="@imagedata"] {
                            border-radius: 50%;
                            flex-shrink: 0;
                            height: 84px;
                            margin-right: 24px;
                            overflow: hidden;
                            width: 84px;
                        }

                        [data-field="@clickableuri"] {
                            display: block;
                            margin-right: 0;
                        }

                        [data-field="@positiontitle"],
                        [data-field="@emaillink"] {
                            &:not(:first-child) {
                                margin-top: 8px;
                            }
                        }

                        [data-field="@positiontitle"] {
                            span {
                                font-size: 16px;
                                line-height: 28px;
                            }
                        }

                        [data-field="@emaillink"] {
                            a {
                                color: $grey;
                                text-decoration: none;
                            }
                        }
                    }

                    &-document {
                        .CoveoResultLink::after {
                            content: none;
                        }
                    }

                    &-tools {
                        justify-content: space-between;

                        // Add to Tools button styling
                        .toolsButton {
                            border: none;
                            flex-shrink: 0;
                            font-size: 14px;
                            font-family: $sans-serif-normal;
                            font-weight: $sans-normal;
                            margin-left: 24px;
                            text-transform: none;

                            &::before {
                                content: "\f067";
                                font-family: $fontAwesome;
                                padding-right: 8px;
                            }

                            &[data-intoolslist="true"] {
                                &::before {
                                    content: "\f00c";
                                }
                            }
                        }
                        .tool-description{
                            a{
                                color: $red;
                                &:hover{
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                    
                    .CoveoFieldValue {                
                        &[data-field="@departmentname"] {
                            span {
                                color: $grey;
                            }
                        }
                    }

                }

                [data-field="@imagedata"]:not(.profileimage) {
                    span:not(:empty) {
                        display: block;
                        flex-shrink: 0;
                        font-size: 0;
                        height: 156px;
                        margin-right: 24px;
                        overflow: hidden;
                        position: relative;
                        width: 156px;
                    }

                    & img,
                    .ytthumbnail img {
                        height: 100%;
                        left: 50%;
                        max-width: none;
                        position: absolute;
                        transform: translateX(-50%);
                        width: auto;
                    }
                }

                .ytthumbnail {
                    margin-right: 24px;
                }

                .coveoforsitecore-template .coveoforsitecore-information-section {
                    padding: 0;
                }

                .coveo-result-cell.coveoforsitecore-time-cell,
                .coveo-result-cell.coveoforsitecore-details-section,
                .coveo-result-cell.coveo-quick-view-container {
                    display: none;
                }

                .CoveoResultLink {
                    @extend %data-chevron-true;
                    color: $red;
                    font-size: 18px;

                    &:hover {
                        text-decoration: none;
                    }

                    &::after {
                        visibility: visible;
                    }

                    &,
                    .coveo-highlight {
                        font-family: $sans-serif-medium;
                        font-weight: $sans-medium;
                    }

                    &.subtitle {
                        font-family: $sans-serif-normal;
                        font-weight: $sans-normal;
                        line-height: 20px;

                        &:hover {
                            span {
                                color: $red;
                            }
                        }

                        &::after {
                            content: none;
                        }

                        span {
                            color: $grey;
                            word-break: break-word;
                        }
                    }
                }

                .CoveoExcerpt {
                    font-size: 16px;
                    line-height: 28px;

                    &:not(:empty) {
                        display: block;
                        margin-top: 10px;
                    }
                }

                [data-field="@topicname"],
                button:not(.toolsButton) {
                    display: none;
                }

                .CoveoFieldValue.tool-title {
                    color: $red;
                    font-size: 18px;
                    font-family: $sans-serif-medium;
                    font-weight: $sans-medium;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        // No results facet:
        &.coveo-no-results {
            margin-right: auto;
            max-width: 1002px;
            padding: 0 24px;
        }
    }

    // Pagination:
    .CoveoPager {
        display: block;
        margin: 0;
        max-width: 752px;
        padding: 0 32px;

        .coveo-pager-list {
            align-items: center;
            display: flex;
            justify-content: center;

            &-item {
                &::before {
                    content: none;
                }

                &,
                &-text {
                    border: none;
                }

                &-text {
                    color: $darkgrey;
                }

                &:hover,
                &.coveo-active {
                    background-color: transparent;

                    * {
                        color: $red;
                        font-family: $sans-serif-medium;
                        font-weight: $sans-medium;
                    }
                }

                &:hover {
                    &,
                    * {
                        text-decoration: none;
                    }
                }

                &.coveo-active {
                    border-bottom: 2px solid $red;
                    border-radius: 0;
                    font-family: $sans-serif-medium;
                    font-weight: $sans-medium;
                }
            }
        }

        .coveo-pager-previous,
        .coveo-pager-next {
            padding: 4px 8px;

            a {
                border: none;
            }

            svg {
                display: none;
            }

            &-icon {
                align-items: center;
                display: flex;
                height: 12px;
                width: 8px;

                &::after {
                    color: $red;
                    font-family: $fontAwesome;
                    font-size: 12px;
                }
            }
        }

        .coveo-pager-previous-icon {
            &::after {
                content: "\f053";
            }
        }

        .coveo-pager-next-icon {
            &::after {
                content: "\f054";
            }
        }
    }

    .CoveoLogo {
        display: none;
    }
}

@media (max-width: 800px) {
    .CoveoSearchInterface {
        // Mobile filters:
        &.coveo-small-facets,
        &.coveo-small-recommendation,
        &.coveo-small-tabs {
            .coveo-main-section {
                .coveo-dropdown-header-wrapper {
                    justify-content: flex-start;
                    padding: 24px;
                }

                .coveo-facet-dropdown-header,
                .coveo-tab-dropdown-header {
                    align-items: center;
                    border: 1px solid $red;
                    height: auto;
                    padding: 10px;
                }

                .coveo-facet-dropdown-header {
                    display: flex;

                    &::before {
                        color: $red;
                        content: "\f1de";
                        font-family: $fontAwesome;
                        margin-right: 6px;
                    }
                }

                .coveo-tab-dropdown-header {
                    display: inline-flex;
                }

                .coveo-facet-column {
                    width: 100%;

                    &.coveo-facet-dropdown-content {
                        .CoveoFacet {
                            padding: 12px;

                            .coveo-facet-header {
                                border-top: none;
                            }

                            ~ .CoveoFacet {
                                border-top: 1px solid $mediumergrey;
                            }
                        }
                    }
                }
            }
        }

        .coveo-main-section {
            margin-bottom: 48px;

            .coveo-search-section {
                margin-bottom: 16px;
            }

            .coveo-tab-section {
                margin-bottom: 32px;
            }

            .coveo-results-column {
                margin-right: 0;
                width: 100%;

                .CoveoResultList {
                    margin: 24px 0;

                    .coveo-list-layout.CoveoResult {
                        margin: 0 24px;
                    }
                }
            }

            .CoveoQuerySummary {
                padding-left: 24px;
                padding-right: 8px;
            }

            .coveo-sort-section {
                padding-left: 8px;
                padding-right: 24px;
            }
        }
    }
}

@media (max-width: $mobile) {
    .CoveoSearchInterface .coveo-main-section {
        // Search Bar:
        .coveo-search-section {
            padding: 48px 0;
        }

        // Results List:
        .coveo-results-column {
            .CoveoResultList {
                .coveo-card-layout-container {
                    display: block;
                }

                // Result Item:
                .coveo-list-layout.CoveoResult {
                    margin: 0 24px;

                    [data-field="@imagedata"]:not(.profileimage) {
                        span:not(:empty) {
                            height: 100px;
                            margin-right: 16px;
                            width: 100px;
                        }
                    }

                    .CoveoResultLink {
                        font-size: 16px;
                    }

                    .CoveoExcerpt {
                        font-size: 14px;

                        &:not(:empty) {
                            margin-top: 4px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .CoveoSearchInterface {
        .coveo-main-section {
            .coveo-search-section {
                .CoveoSearchbox {
                    margin-right: 0;
                }
            }
        }
    }
}
