@import "../scss/variables";


form[data-formtype=search] {
    display: flex;
    justify-content: center;
    flex-wrap:wrap;

    [class="fa fa-search"] {
        align-items: center;
        display: inline-flex;
        justify-content: center;
        position: relative;

        &:hover {
            &::before {
                color: $red;
            }
        }

        &::before {
            pointer-events: none;
            position: absolute;
            transition: color 0.3s ease-in-out;
        }
    }

    input {
        &[type=search],
        &[type=submit] {
            border: 2px solid $lightgrey;
        }

        &[type=search] {
            color: $darkgrey;
            line-height: 16px;
            padding: 18px;
            transition: border-color 0.3s ease-in-out;
            width: 615px;

            &::placeholder {
                color: $anothergrey;
            }
        }

        &[type=submit] {
            background: $white;
            cursor: pointer;
            height: 100%;
            line-height: 16px;
            padding: 18px 28px;
            transition: border-color 0.3s ease-in-out;
        }
    }
    @media  (max-width: $mobile-larger) {
         justify-content: center;

         label {
             visibility: hidden;
         }
        .PeopleDirectoryLink {
            display: none;
        }
         input{
            
            &[type=search] {
            width: 60vw !important;
            }
         }  
     }
}