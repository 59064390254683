ul,
ol {
    list-style-position: outside;

    li {
        font-size: 18px;
        line-height: 28px;
        & + li {
            margin-top: 8px;
        }
    }
}

ul { 
    li {
        display: flex;
        list-style-type: none;

        &::before {
            content: "\2022";
            font-size: 17px;
            margin-right: 14px;
        }
    }
}

ol {
    margin-left: 20px; // Accounts for 'outside' position of numbers
}

@media (max-width: $mobile) {
    ul,
    ol {
        li {
            font-size: 14px;
            line-height: 24px;
        }
    }
}
