@import './layouts';

.linkList {
    @extend %containerSpacing;
    background-color: $lightergrey;
    padding: 48px 0;

    &-inner {
        @extend .single-col-inner;
    }

    &-heading {
        @extend .sans-head-2;
        display: block;
        margin-bottom: 96px;
    }

    &-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 32px;
        grid-column-gap: 104px;

        // IE grid fixes:
        display: -ms-grid;
        -ms-grid-columns: 1fr 104px 1fr 104px 1fr;
    }

    &-listItem {
        // Reset list styles
        display: block;
        & + & {
            margin-top: 0;
        }    
        &::before {
            content: none;
        }

        // IE Grid Columns:
        &:nth-child(3n + 1) {
            -ms-grid-column: 1;
        }
                    
        &:nth-child(3n + 2) {
            -ms-grid-column: 3;
        }

        &:nth-child(3n + 3) {
            -ms-grid-column: 5;
        }
                
        // IE Grid Rows:
        @for $i from 1 through 20 {
            &:nth-child(#{$i}) {
                @if $i % 3 == 0 {
                    -ms-grid-row: calc(#{$i} / 3);
                }
                @else if ($i + 1) % 3 == 0 {
                    -ms-grid-row: calc((#{$i} + 1) / 3);
                }
                @else {
                    -ms-grid-row: calc((#{$i} + 2) / 3);
                }
            }
        }
    }

    &-link {
        font-family: $sans-serif-medium;
        font-weight: $sans-medium;
        letter-spacing: .4px;
        line-height: 18px;
    }

    &-description {
        font-size: 14px;
        line-height: 22px;
        margin-top: 6px;
    }
}

// Fun lil IE fix
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .linkList-listItem {
        margin-bottom: 24px;
    }
}

@media (max-width: $desktop-smaller) {
    .linkList {
        &-list {
            grid-column-gap: 48px;

            // IE grid fixes:
            -ms-grid-columns: 1fr 48px 1fr 48px 1fr;
        }
    }
}

@media (max-width: $mobile) {
    .linkList {
        &-heading {
            margin-bottom: 72px;
        }

        &-list {
            grid-template-columns: 1fr 1fr;

            
            // IE grid fixes:
            -ms-grid-columns: 1fr 48px 1fr;
        }

        &-listItem {
            // IE Grid Columns:
            &:nth-child(2n + 1) {
                -ms-grid-column: 1;
            }
                        
            &:nth-child(2n + 2) {
                -ms-grid-column: 3;
            }
                    
            // IE Grid Rows:
            @for $i from 1 through 20 {
                &:nth-child(#{$i}) {
                    @if $i % 2 == 0 {
                        // Even - divide by two
                        -ms-grid-row: calc(#{$i} / 2);
                    }
                    @else {
                        // Odd - plus one, divide by two
                        -ms-grid-row: calc((#{$i} + 1) / 2);
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-smaller) {
    .linkList {
        &-heading {
            margin-bottom: 60px;
        }

        &-list {
            grid-template-columns: 1fr;

            // IE grid fixes:
            -ms-grid-columns: 1fr;
        }

        &-listItem {
            // IE Grid Columns:                        
            &:nth-child(2n + 2) {
                -ms-grid-column: 1;
            }
                    
            // IE Grid Rows:
            @for $i from 1 through 20 {
                &:nth-child(#{$i}) {
                    -ms-grid-row: $i;
                }
            }
        }
    }
}